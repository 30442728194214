/**
 * Custom icon list
 * All icons are loaded here for easy management
 * @see https://vue.ant.design/components/icon/#Custom-Font-Icon
 *
 * 自定义图标加载表
 * 所有图标均从这里加载，方便管理
 */
import hstLogo from '@/assets/icons/tongyong_logo.svg?inline' // hst logo path to your '*.svg?inline' file.
import meetingInfo from '@/assets/icons/dingbulan_xinxi.svg?inline' // 会议信息
import fullScreen from '@/assets/icons/quanping_fangda.svg?inline' // 全屏放大
import fullScreenBack from '@/assets/icons/quanping_suoxiao.svg?inline' // 全屏缩小
import fullScreenBacks from '@/assets/icons/gongjulan_tuichuquanping.svg?inline' // 工具栏全屏缩小

import audioVol from '@/assets/icons/gongjulan_yuyinbufen.svg?inline' // 语音音量
import audioMic from '@/assets/icons/gongjulan_yuyin.svg?inline' // 语音麦克风
import audioMicVolume1 from '@/assets/icons/gongjulan_yuyin1.svg?inline' // 语音音量1
import audioMicVolume2 from '@/assets/icons/gongjulan_yuyin2.svg?inline' // 语音音量2
import audioMicVolume3 from '@/assets/icons/gongjulan_yuyin3.svg?inline' // 语音音量3
import audioMicVolume4 from '@/assets/icons/gongjulan_yuyin4.svg?inline' // 语音音量4
import audioMicVolume5 from '@/assets/icons/gongjulan_yuyin5.svg?inline' // 语音音量5
import audioMicVolume6 from '@/assets/icons/gongjulan_yuyin6.svg?inline' // 语音音量6
import audioHandUp from '@/assets/icons/gongjulan_yuyinjushou.svg?inline' // 语音举手
import audioStop from '@/assets/icons/gongjulan_tingzhiyuyin.svg?inline' // 语音停止
import noaudio from '@/assets/icons/gongjulan_wumaikefeng.svg?inline'
import videoIcon from '@/assets/icons/gongjulan_shipin.svg?inline' // 视频
import videoHandUp from '@/assets/icons/gongjulan_shipinjushou.svg?inline' // 视屏举手
import videoStop from '@/assets/icons/gongjulan_tingzhishipin.svg?inline' // 视频停止
import novideo from '@/assets/icons/gongjulan_wushexiangtou.svg?inline'
import layout from '@/assets/icons/gongjulan_buju.svg?inline' // 工具栏布局
import share from '@/assets/icons/gongjulan_share.svg?inline' // 共享按钮
import chat from '@/assets/icons/gongjulan_chat.svg?inline' // 聊天按钮
import toolBarInviteIcon from '@/assets/icons/gongjulan_invite.svg?inline' // 邀请图标

import recordStart from '@/assets/icons/record/tb_record.svg?inline'
import recordingStop from '@/assets/icons/record/tb_recording.svg?inline'

// 参会者列表
import toolBarUserIcon from '@/assets/icons/user/tb_participate.svg?inline' //参会者图标

import exitMeeting from '@/assets/icons/gongjulan_tuichu.svg?inline' // 退出
import leveMeeting from '@/assets/icons/tuichudanchuang_likaihuiyi.svg?inline' // 退出会议（离开）
import endMeeting from '@/assets/icons/tuichudanchuang_tuichu.svg?inline' // 退出会议（结束）
import close from '@/assets/icons/tongyonganniu_guanbi.svg?inline' // 关闭
import layoutVideoIcon from '@/assets/icons/video_icon.svg?inline' // 视频

// 共享白板 底部工具
import switchShareIcon from '@/assets/icons/sharetoolbar/ctrlbar_tabChange.svg?inline' // 切换白板
import addBoardShareIcon from '@/assets/icons/sharetoolbar/ctrlbar_new.svg?inline' // 添加白板
import showToolShareIcon from '@/assets/icons/sharetoolbar/ctrlbar_marking.svg?inline' // 显示划线工具
import showToolClickShareIcon from '@/assets/icons/sharetoolbar/ctrlbar_marking_focus.svg?inline' // 显示划线工具
import roateToolIcon from '@/assets/icons/sharetoolbar/ctrlbar_revolve_press.svg?inline' // 旋转
import fixToolIcon from '@/assets/icons/sharetoolbar/ctrlbar_fixed_press.svg?inline' // 钉住前
import fixHotToolIcon from '@/assets/icons/sharetoolbar/ctrlbar_fixed_focus_hot.svg?inline' // 钉住
import closeIcon from '@/assets/icons/sharetoolbar/sharetab_close.svg?inline' // 关闭按钮

// 共享白板划线工具
import closeLineIcon from '@/assets/icons/marktoolbar/marktoolbar_close.svg?inline' // 关闭按钮
import toolSelectIcon from '@/assets/icons/marktoolbar/wb_tool_select.svg?inline' // 移动选择
import toolSelectFocusIcon from '@/assets/icons/marktoolbar/wb_tool_select_focus.svg?inline' // 移动选择
import eraserFocusIcon from '@/assets/icons/marktoolbar/wb_tool_eraser_focus.svg?inline' // 橡皮擦
import eraserHotIcon from '@/assets/icons/marktoolbar/wb_tool_eraser.svg?inline' // 橡皮擦
import pencilFocusIcon from '@/assets/icons/marktoolbar/wb_tool_pencil_focus.svg?inline' // 荧光笔
import pencilHotIcon from '@/assets/icons/marktoolbar/wb_tool_pencil.svg?inline' // 荧光笔
import textFocusIcon from '@/assets/icons/marktoolbar/wb_tool_text_focus.svg?inline' // 荧光笔
import textHotIcon from '@/assets/icons/marktoolbar/wb_tool_text.svg?inline' // 文字

// 登录页面
import userIcon from '@/assets/img/login/user.svg?inline'
import lockIcon from '@/assets/img/login/lock.svg?inline'
import roomIcon from '@/assets/img/login/room.svg?inline'
import nameIcon from '@/assets/img/login/name.svg?inline'

export {
  recordStart,
  recordingStop,
  hstLogo,
  fullScreen,
  fullScreenBack,
  fullScreenBacks,
  meetingInfo,
  audioVol,
  audioMic,
  audioMicVolume1,
  audioMicVolume2,
  audioMicVolume3,
  audioMicVolume4,
  audioMicVolume5,
  audioMicVolume6,
  audioHandUp,
  audioStop,
  noaudio,
  videoIcon,
  videoHandUp,
  videoStop,
  novideo,
  layout,
  share,
  exitMeeting,
  leveMeeting,
  endMeeting,
  close,
  layoutVideoIcon,
  switchShareIcon,
  addBoardShareIcon,
  showToolShareIcon,
  showToolClickShareIcon,
  roateToolIcon,
  fixToolIcon,
  fixHotToolIcon,
  closeIcon,
  closeLineIcon,
  toolSelectIcon,
  toolSelectFocusIcon,
  eraserFocusIcon,
  eraserHotIcon,
  pencilFocusIcon,
  pencilHotIcon,
  textFocusIcon,
  textHotIcon,
  userIcon,
  lockIcon,
  roomIcon,
  nameIcon,
  chat,
  toolBarUserIcon,
  toolBarInviteIcon
}
